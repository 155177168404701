@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
$primary : #0085CA;
@import '~bootstrap/scss/bootstrap.scss';

.open-sans-400 {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

body {
  @extend .open-sans-400;
}

.App {
  min-height: 100vh;
}

.form-control:focus,
.form-select:focus {
  box-shadow: none;
}

.password-toggle {
  position: relative;

  .password-toggle-btn {
    position: absolute;
    right: 10px;
    top: 15px;
  }
}

/* Dashboard.css */
.dashboard-container {
  display: flex;
  height: 100vh;
}

.sidebar ul li a {
  color: #fff;
  text-decoration: none;
}

.main-content {
  flex: 1;
  padding: 100px;
}

.table-container,
.video-upload-container {
  margin-bottom: 20px;
}

.video-upload-container {
  margin-bottom: 40px;
  /* Extra space for separation */
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table:not(.select-brand-table) th,
.table:not(.select-brand-table) td {
  padding-top: 8px;
  padding-bottom: 8px;
}

.table th {
  background-color: #2c3e50;
  color: white;
}

.custom-table table tbody tr {
  vertical-align: top;
}

.table-middle {
  td {
    vertical-align: middle;
  }
}

.video-upload p {
  margin: 0;
}

/* VideoUpload.css */

.upload-content {
  justify-content: space-between;
  width: 100%;
  &.textarea_section {
    flex-wrap: wrap;  
    .left-content {
      width: 100%;

      textarea {
        width: 100%;

      }
    }
  }

  .left-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

}


.left-content p {
  margin: 10px 0 5px 0;
}

.left-content small {
  color: #666;
}

.right-content {
  align-items: center;
}

.right-content button {
  padding: 8px 16px;
  background-color: #0085CA;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.right-content button:hover {
  background-color: #0085CA;
}

.right-content button:disabled {
  background-color: #0085CA;
}

.css-d7jkuz-MuiTable-root th {
  color: white;
  height: 3rem;
}

/* Sidebar.css */
.sidebar {
  width: 350px;
  background: #ecf0f1;
  color: black;
  /* Change text color to black */
  padding: 15px;
  position: relative;
  transition: transform 0.3s ease;
  transform: translateX(0);
}

.closed {
  transform: translateX(-100%);
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-icon {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5em;
  cursor: pointer;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin: 15px 0;
}

.sidebar ul li button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  text-align: left;
  padding: 0;
}

.modal-configuration {
  margin-top: 20px;
}

.modal-configuration h3 {
  margin-bottom: 10px;
}

.modal-configuration h4 {
  margin-bottom: 10px;
}

.modal-configuration label {
  display: block;
}

.modal-configuration input[type="range"] {
  width: 100%;
  margin-top: 5px;
}

.open-sidebar-btn {
  background: #3498db;
  border: none;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  position: absolute;
  bottom: 15px;
  left: 15px;
}

.open-sidebar-btn:hover {
  background: #2980b9;
}

/* Dashboard.css */
.dashboard-container {
  position: relative;
}

.dashboard-header {
  position: absolute;
  top: 15px;
  right: 4rem;
}

.menu-button {
  background: none;
  border: none;
  color: #000;
  font-size: 1.5em;
  cursor: pointer;
}

.logout-menu {
  position: absolute;
  top: 40px;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 5px 0;
}

.logout-menu button {
  display: block;
  width: 100%;
  padding: 10px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
}

.logout-menu button:hover {
  background-color: #f2f2f2;
}

.video-details-table {
  width: 96%;
  border-collapse: collapse;
  margin: auto;
}

.video-player-modal {
  width: 96%;
  border-collapse: collapse;
  margin: auto;
}

.video-details-table th,
.video-details-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.video-details-table th {
  background-color: #f2f2f2;
}

.video_links {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
}

.video_links span {
  background-color: #e1e1e1;
  padding: 5px 15px;
  margin: 4px;
  border-radius: 25px;
}

button.Toastify__close-button {
  width: auto;
  margin: 0;
}

.Toastify__toast-body {
  width: 100%;
}

/////////////////////////////

.new-dasbhoard-wrap {
  .count {
    font-size: 30px !important;
    font-weight: 600;
  }
  .dashboard-card {
    &.card {
      border: 0px;
      border-radius: 12px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

      .card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        min-height: 180px;

        .title {
          font-size: 24px;
          font-weight: 500;
        }

        .view-btn {
          border-radius: 20px;
        }
      }
    }
  }

  .dashboard-table {
    overflow: hidden;
    margin: 10px;
    height: 100%;

    .card {
      box-shadow: 0px 0px 2px rgba(7, 91, 169, 0.16);
      border-radius: 10px;
      height: 100%;

      .card-body {
        padding: 0;
        margin: 0;

        .title {
          text-align: center;
          font-size: 20px;
          font-weight: 600;
          margin: 16px 0;
        }
        .sub-title {
          font-size: 18px;
          font-weight: 600;
          text-align: center;
          margin-bottom: 20px;
          height: 50px !important;
        }
        .table {
          margin-bottom: 0;
          thead {
            tr {
              border-bottom: 1px solid #e7eef4;
              td {
                box-shadow: none;
                font-size: 15px;
                font-weight: 600;
                padding: 14px;
                background-color: #f9fbfc;
                color: #0085CA;
                &:first-child,
                &:last-child {
                  overflow: hidden;
                  // border-radius: 10px;
                }
              }
            }
          }
          tbody {
            tr {
              td {
                padding: 14px;
                span {
                  font-size: 14px;
                  font-weight: 600;
                }
          
                &:first-child,
                &:last-child {
                  overflow: hidden;
                  border-radius: 10px;
                }
              }
          
              &:nth-child(even) {
                td {
                  background-color: #f9fbfc;
                  border-radius: 0px;
                }
              }
              &:nth-child(even):last-child {
                td:first-child {
                  border-radius: 0px 0px 0 10px;
                }
                td:last-child {
                  border-radius: 0 0px 10px 0;
                }
              }
            }
          }
          
        }
      }
    }
    .inner-card {
      margin: 24px;
      border: 1px solid #d2d2d2;
      border-radius: 10px;
    }
  }
}

.video-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.border-dashed {
  background-image: repeating-linear-gradient(-1deg, #707070, #707070 8px, transparent 8px, transparent 11px, #707070 11px), repeating-linear-gradient(89deg, #707070, #707070 8px, transparent 8px, transparent 11px, #707070 11px), repeating-linear-gradient(179deg, #707070, #707070 8px, transparent 8px, transparent 11px, #707070 11px), repeating-linear-gradient(269deg, #707070, #707070 8px, transparent 8px, transparent 11px, #707070 11px);
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

.dashboard-border-graph {
  @extend .border-dashed;
  padding: 1rem 1rem;
  cursor: pointer;
}

.upload-area {
  @extend .border-dashed;
  padding: 30px 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
}

.dashboard-border-delear-brand {
  @extend .border-dashed;
  padding: 1rem 1rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.submit-url-area {
  @extend .border-dashed;
  padding: 30px 1rem;
  display: flex;
  flex-direction: column;
  justify-content: between;
  cursor: pointer;
  gap: 1.5rem;
  height: 100%;

  .submit-url-textarea {
    border: none;
    height: 100%;
    background-color: #F7F7F7;
    border-radius: 0;
    padding: 1rem;
  }
}

.upload-actions {
  margin-top: 20px;
}

.upload-actions button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.upload-actions button:hover {
  background-color: #0056b3;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  margin-bottom: 0px;
  list-style: none;

  li {
    margin: 0 6.5px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      height: 40px;
      background-color: #e1e1e1;
      border-radius: 5px;
      text-decoration: none;
      transition: 0.5s;
      padding: 5px;
      font-size: 18px;
      font-weight: 600;

      &:hover {
        color: #0d6efd !important;
      }
    }
  }

  .pagination__link--active {
    a {
      background-color: #0085CA;
      color: white;

      &:hover {
        color: white !important;
      }
    }
  }

  .password-container {
    position: relative;
  }

  .password-toggle-btn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    padding: 0;
    font-size: 0.9rem;
  }

  .form-check {
    display: flex;
    align-items: center;
  }
}

.alice-carousel__wrapper li {
  text-align: center;
}

.alice-carousel__wrapper li img {
  height: 700px;
  width: 700px;
  padding-top: 5%;
  padding-bottom: 5%;
  margin: 0;
}

.alice-carousel__prev-btn {
  text-align: right !important;
  position: absolute !important;
  right: 0 !important;
  width: 50px !important;
  height: 60px !important;
  font-size: 30px !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  top: calc(50% - 35px) !important;
  left: 0 !important;
}

.alice-carousel__next-btn {
  text-align: left;
  position: absolute !important;
  right: 0 !important;
  width: 50px !important;
  height: 60px !important;
  font-size: 30px !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  top: calc(50% - 35px) !important;
  right: 0 !important;
}

.thumbnails {
  width: 100px;
  height: 100px;
  object-fit: contain;
  cursor: pointer;
}
.thumbnail-modal-image {
 width: 500px;
 height: 500px;
 object-fit: contain;
}

.table-alice-carousel {
  max-width: 666px;
  @media (max-width: 991px){
    max-width: 350px;
  }
  .mySwiper {
    padding: 0 30px;
    width: calc(100% - 0px);
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 0px;
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 0px;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after,
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    font-size: 22px;
  }

}

.video-details-modal {
  z-index: 1055;

  .modal-content {
    border: none;
    border-radius: 0.25rem;
  }

  .modal-header {
    background-color: $primary;
    color: #fff;
    padding: 0.9rem 0.9rem 0.9rem 1.5rem;
    justify-content: space-between;
    border-bottom: none;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
}

.video-details-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;

  .video-detail {
    display: flex;
    align-items: center;
    gap: 1rem;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    .video-detail-title {
      font-size: 18px;
      color: #000000;
    }

    .video-detail-desc {
      flex: 1 1 auto;
      font-size: 1rem;
      color: #4D4D4D;
    }
  }
}


@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotate-icon {
  display: inline-block;
  /* Ensures the icon rotates around its center */
  animation: rotate 0.8s linear infinite;
  /* Adjust the duration (2s) as needed */
}

.status-progressbar {
  min-width: 100px;

  >div {
    position: relative;

    span {
      // filter: drop-shadow(2px 4px 6px black);
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      padding: 0 !important;
      line-height: 20px;
      font-size: 14px !important;
      bottom: 0;
      top: 0px;
    }
  }
}

.custom-btn {
  width: auto;
  font-size: 20px;
  padding: 0.35rem 1rem;
}

.link-color {
  color: #0085CA;
}

.custom-table {
  border-radius: 0 !important;
  border: 1px solid #DEDEDE;
  box-shadow: none !important;

  th,
  td {
    padding: 10px 10px;
  }

  th {
    font-weight: 400;
    white-space: nowrap;
    background-color: #0085CA;
    color: #fff;

    &:last-of-type {
      padding-right: 27px;
    }
  }

  td {
    color: #000 !important;
  }

  .tbody-tr:nth-child(odd) .tbody-td {
    background-color: #F2F2F2;
  }

  .tbody-tr:nth-child(even) .tbody-td {
    background-color: #fff;
  }
}

.dropdown-menu {
  cursor: pointer;
}

.custom-dropdown {
  .dropdown-menu {
    border: none;
    padding: 0;
  }

  .custom-dropdown-menu {
    padding: 1rem 0.5rem;
    background-color: #F4F4F4;
    border: 1px solid #DEDEDE;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

  }
}

.btn-primary {
  color: #fff;
  border-radius: 4px;

  &:disabled,
  &:hover,
  &:focus,
  &:active,
  &.active,
  &:focus:active {
    color: #fff;
  }
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  border-color: transparent;
}

.w-md-80 {
  @include media-breakpoint-down(md) {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    width: 80%;
  }
  @include media-breakpoint-down(md) {
    width: 75%;
  }

}

.break-word {
  word-wrap: break-word;
  word-break: break-word;
}


.delete-confirm {
  position: fixed;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  .inner {
    width: 100%;
    max-width: 435px;
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
  }
}

.header {
  .nav-link {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: center;
    font-weight: 600;
    font-size: 13px;
    padding: 5px !important;
  }

  .navbar-collapse {
    @include media-breakpoint-down(lg) {
      box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;
      position: relative;
      padding: 0 10px;
    }
  }
}

.navbar-nav .nav-link.active {
  font-weight: bold;
  color: rgb(51, 157, 213);
}

.navbar-nav .nav-link:hover {
  font-weight: bold;
  color: rgb(51, 157, 213);
}

.dealers-dropdown {
  @include media-breakpoint-up(md) {
    width: 130px !important;
    max-width: 130px !important;
    min-width: 130px !important;
  }

  @include media-breakpoint-down(md) {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
}

.total-videos {
  @include media-breakpoint-down(lg) {
    display: flex;
    justify-content: center;

    .card {
      width: 300px;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 2rem;
    padding-bottom: 1rem;

    .card {
      height: 100%;

      .card-body {
        padding: 0.5rem 0.25rem;
      }
    }
  }

  .card-title {
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
}

.filter-options {
  input {
    border-radius: 50em 0 0 50em;
    border-right: none;
    padding: 12px 12px 12px 16px;

    &:focus {
      box-shadow: none;
      border-right: none;
    }
  }

  button {
    border-radius: 0 50em 50em 0;
    padding:  11px 12px 9px;
    text-align: center;
    margin: 0 !important;
  }
}

.custom-pagination {
  button {
    border-radius: 20px;
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;

    li {
      button {
        border-radius: 50%;
      }
    }
  }
}

.delete-confirm {
  position: fixed;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  .delete-confirm-inner {
    width: 100%;
    max-width: 600px;
    background: #f5f6fd;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);

    .delete-confirm-title {
      text-align: center;
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 28px;
    }

    @media(max-width: 991.98px) {
      .delete-confirm-inner {
        max-width: none;
      }
    }
  }
}

.custom-pagination {
  button {
    border-radius: 20px;
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    gap: 10px;

    li {
      button {
        border-radius: 50%;
      }
    }
  }
}

.p-12px {
  padding: 12px;
}

label.Mui-disabled {
  color: rgba(0, 0, 0, 0.6) !important;
}

input.Mui-disabled {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.7) !important;
}

.size-34 {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-primary-light {
  background-color: #e1f1f9;
}

.tbody-td span {
  display: block;
  text-align: center;
}

.text-black-disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5;
}

.report-options {
  width: 180px;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.assigned-label {
  color: #ffffff;
  text-align: center;
  background-color: #005b8b;
  border-radius: 50px;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.assigned-list {
  border-radius: 20px;
  height: 100vh;
  overflow-y: auto;
}
.brand-value {
  display: flex;
}

.label-brand {
  padding-right: 20px;
}

input[type=checkbox] {
  height: 24px;
  width: 24px;
  border-radius: 0px !important;
  border: 1px solid #707070;

  &:checked {
    border: 1px solid #0085CA;
    background-image: url("../assets/images/checked.svg");
  }
}
td span input[type=checkbox], th span input[type=checkbox]{
  width: 100%;
  height: 100%;
}
th span input[type=checkbox] ~ svg{
fill: #fff;
}

.brand-div {
  display: flex;
  justify-content: space-around;
}

.edit-dealers {
  vertical-align: middle;
  display: flex;
  justify-content: end;
}

.user-details-tbody {
  font-size: x-large;
}

.toggle-button {
  cursor: pointer;
  color: black;
  font-size: 20px;

  // &.active {
  //   background-color: #0085CA;
  //   color: white;
  // }
}

.size-42 {
  width: 42px;
  height: 42px;
}



/* TyreViewModal.css */
.tyre-modal-container {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
}

.tyre-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f4f8;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.tyre-icon {
  width: 30px;
  height: auto;
}

.btn-primary {
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #0085CA;
  border: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.no-record-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.5rem;
  color: #888;
  text-align: center;
  margin: 20px 0px;
}

.tab-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.btnTire {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  border: 1px solid #f1f1f1;
  background: #ffffff;
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.btnTire:hover {
  background: #ffffff;
}

.btnTire.active {
  background: #0078d4;
  color: white;
  border-color: #0078d4;
}

.btnTire img {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease;
}

.btnTire.active img {
  filter: brightness(100);
}

.tab-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}


.tab-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}
.tire-details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin: 16px;
}

/* Tire Card */
.tire-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 140px;
  border: 1px solid grey;
}

.card-header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.card-icon {
  width: 24px;
  height: 24px;
}

.position-label {
  font-size: 14px;
  font-weight: bold;
  color: #1b1b1b;
  margin: 0;
}

.card-body {
  margin-top: 12px;
}

.card-body h3 {
  font-size: 24px;
  font-weight: bold;
  color: #1a7f44;
  margin: 0 0 8px 0;
}

.status {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin: 0 0 4px 0;
}

.details {
  font-size: 14px;
  color: #555;
  margin: 0;
}

/* Loader (Optional) */
.loading {
  text-align: center;
  font-size: 16px;
  color: #666;
  margin-top: 24px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .tire-details-grid {
    grid-template-columns: 1fr;
    border: 1px solid grey;
  }

  .btnTire {
    font-size: 12px;
  }
}

.tire-icon {
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.tire-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.manual-tread-depth {
  display: flex;
  align-items: center;
  gap: 10px;
}

.manual-depth-input {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  width: 100px;
}

.depth-value {
  font-weight: bold;
}

.edit-button {
  background: none;
  border: none;
  cursor: pointer;
}

.edit-icon {
  width: 15px;
  height: 15px;
}


/* TireDetails.css */
.tire-logo {
  transition: filter 0.3s ease;
}

.status-message {
  margin-bottom: 5px 0;
  padding: 5px 0px;
  display: inline-block;
}

.card-header svg {
  width: 15px;
  height: 28px;
}

.card-header .message-usable path {
  fill: #f5d207;
}

.card-header .message-worn path {
  fill: #FFA500;
}

.card-header .message-critical path {
  fill: #FF0000;
}

.card-header .message-unsafe path {
  fill: #8B0000;
}

.card-header .message-good path {
  fill: #008000;
}
.play-now-button button {
  background-color: #007bff;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.play-now-button button:hover {
  background-color: #0056b3;
}
.thumbnail-image{
  float: right;
  width: 100px;
  height: 100px;
  cursor: pointer;
}
.tread_video .modal-dialog {
  max-width: 400px;
}
.video_playing {
  text-align: center;
  video {
    height: 500px;
    width: 400px;
    object-fit: cover;
    margin: auto;
  }
}
.tread-video {
  display: flex;
  justify-content: space-between; /* Optional: for spreading out content */
  align-items: center; /* Vertically center items */
}
 
.play-now-button-tread  {
  margin-left: auto;
}
.card-header .tread-video{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-content-tread {
  display: flex;
  align-items: center;
  gap: 8px;
}

.position-label {
  margin: 0;
}

.play-now-button {
  display: flex;
  align-items: center;
}



.cs-btn {
  background-color: #ebebeb;
  color: #242a75 !important;
  font-weight: 500;
}

.cs-btn:hover {
  background-color: #bbbdd3;
  color: #ebebeb;
}

.csc-btn {
  background-color: #242a75;
  color: #ebebeb;
  font-weight: 500;
}

.csc-btn:hover {
  background-color: #ebebeb;
  color: #242a75;
}

.tx-btn {
  color: #242a75;
}

.cs-btn i {
  font-weight: 700;
  margin-right: 2px;
}

.cs-pills li.nav-item {
  border: 1px solid #242a75;
  border-radius: 0;
}

.cs-pills .nav-link {
  border-radius: 0 !important;
}

.cs-pills .nav-link.active {
  background-color: #242a75 !important;
}

.cs-pills .nav-link {
  color: #333;
}




// New DESIGN SCSS START HERE

.dashboard-wrapper {
  .header-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: #F2F2F2;
    border: 1px solid #F0F2F3;
    min-height: 100px;
    margin-bottom: 20px;
    padding: 0 23px;

    .header {
      padding: 0px;
      width: 100%;
    }
  }

  &.brand-dealer-wrapper {
    .assigned-list {
      .brand-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        position: sticky;
        top: 0;
        z-index: 9;
        margin-bottom: 20px;

        .title {
          font-size: 30px;
        }
      }

      .select-brand-table {
        tbody {
          tr {
            td {
              height: 48px;
            }
          }
        }

        label {
          font-size: 24px;
          margin-left: 10px;
          line-height: 32px;
        }
      }
    }
  }

  .users {
    padding: 0 23px;

    .query-filter {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 30px;

      .filter-options {
        input {
          border-radius: 5px;
          border: 1px solid #CCCCCC;
          font-size: 18px;
          line-height: 24px;
          padding: 13px 15px;

          &::placeholder {
            color: #AAAAAA;
          }
        }

        button {
          border-radius: 5px;
          width: 50px;
        }
      }

      .create-btn {
        height: 50px;
        border-radius: 5px !important;
        display: flex;
        flex-direction: row-reverse;
        gap: 6px;

        span {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }

    .custom-table {
      border: 0px;
      border-left: 1px solid #E4E7EA;
      border-right: 1px solid #E4E7EA;

      .table {
        .thead {
          tr {
            th {
              font-size: 18px;
              font-weight: 600;
              height: 70px;
              vertical-align: middle;
            }
          }
        }

        .tbody {
          .tbody-tr {
            .tbody-td {
              color: #616763 !important;
              border-top: 1px solid #F0F2F3;
              border-bottom: 1px solid #F0F2F3;
              height: 70px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }

  @media(max-width: 576.98px) {
    .header-wrapper {
      padding: 0;
    }

    .user {
      padding: 0;
    }
  }
}

.toggle-button {
  position: relative;
  display: inline-flex;
  padding-left: 40px;

  &::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    border: 1px solid #707070;
    left: 0px;
    top: 3px;
  }

  &.active {
    &::after {
      content: "";
      position: absolute;
      left: 0px;
      top: 3px;
      background-image: url("../assets/images/checked.svg");
      background-size: contain;
      width: 24px;
      height: 24px;
      border: 1px solid #0085CA;
    }
  }

  label {
    font-size: 24px;
    margin-left: 10px;
  }
}

.custom-modal {
  .modal-dialog {

    .modal-title {
      font-size: 26px !important;
    }

    .footer-btn {
      margin: 0 -28px;
      padding: 0px 19px 9px 19px;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.txt-error {
  font-size: 14px;
}

.modal-btn {
  height: 50px;
  min-width: 150px;
  border-radius: 5px;
  font-size: 18px;
}

.manage-brand-btn {
  // height: 40px;
  min-width: 90px;
  border-radius: 5px;
  font-size: 18px;
}

.user-detial-table {
  .table {
    .user-details-tbody {
      tr {
        td {
          font-size: 18px;
        }
      }
    }
  }
}

.action-btn {
  height: 50px;
  border-radius: 5px;
  min-width: 140px;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-btn {
  height: 40px;
  width: 40px;
  background-color: rgba(0, 0, 0, 0.04) !important;
  padding: 0px !important;
}

// NEW DESIGN SCSS END HERE
.mainContainer {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.input-container {
  margin-bottom: 20px;
}

.input-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.combined-field {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
}

.methodModal {
  width: 100%;
  margin: 0 !important;
  max-width: none !important;
}

.method-dropdown {
  width: 130px;
  border: none;
  padding: 10px;
  font-size: 14px;
  background-color: #f9f9f9;
  text-align: center;
}

.method-dropdown:focus {
  outline: none;
  background-color: #fff;
}

.url-input-field .input-container {
  flex: 1;
  border: none;
  padding: 10px;
  font-size: 14px;
}

.url-input-field:focus {
  outline: none;
}

.text-danger {
  margin-top: 5px;
  font-size: 12px;
  color: red;
}

.signup-btn {
  display: block;
  width: 20%;
  padding: 5px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.signup-btn:hover {
  background-color: #0056b3;
}

.mainContainer .input-container .no-border {
  border: 0 !important;
  margin: 0
}

.exp-coll-btn {
  padding: 0;
  text-align: start;
  white-space: pre;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.fixed-height-container {
  max-height: 500px;
  -ms-overflow-style: none;
}

.fixed-height-container::-webkit-scrollbar {
  width: 8px;
}

.fixed-height-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.accordion-content {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #fcfcfc;
  margin-bottom: 10px;
  max-height: 450px;
  overflow-y: auto;
}

.accordion-inner-content p,
.accordion-inner-content pre {
  margin: 5px 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.scrollable-content {
  max-height: 150px;
  overflow-y: auto;
  padding: 10px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.mapBrandDealer-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons-container {
  display: flex;
  gap: 10px;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#form1 {
  padding-right: 40px;
}
// .search-btn {
//   position: absolute !important;
//   right: 0;
//   bottom: 0;
//   top: 0;
// }
.search-height{
  padding: .96rem 0.75rem;
}
.filter-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.img-fluid-user-list-icons {
  max-width: 80%;
  height: auto;
}
.filter-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 300px;
  background: #fff;
  padding: 16px;
}

.action-dropdown {
  right: 114px;
  width: 180px;
  padding: 0px 16px 16px;
}
.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #0085CA;
}
.filter-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.fitlerIcon{
  max-width: 80%;
  height: auto;
}

.checkbox-custom {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding-left: 9px;

  .MuiCheckbox-root {
    padding: 0;
  }
}
.nav-tabs .nav-link.active {
  background-color: #0085CA !important;
  color: white !important;
  border-color: #0085CA !important;
}

.nav-tabs .nav-link {
  color: black !important;
  font-weight: 600;
  text-transform: uppercase;
}

.nav-tabs .nav-link:hover {
  color: white !important;
  background-color: #005a8d !important;
}

.progress-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.retry-icon-container {
  width: 24px; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.retry-placeholder {
  width: 24px;
  height: 24px;
  display: inline-block;
  visibility: hidden; 
}
