.form-row {
    display: flex;
    gap: 20px;
    flex-wrap: wrap; /* For responsiveness */
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.form-field {
    flex: 1; /* Allow equal width */
    min-width: 200px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-select,
.MuiTextField-root {
    width: 100%;
}

.submit-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.submit-btn:hover {
    background-color: #0056b3;
}

.Mui-disabled {
    background-color: #e9ecef;    
}