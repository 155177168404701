/* WebHistory.css */

.webhook-history-container {
    margin: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .webhook-table {
    margin-top: 20px;
    border-collapse: collapse;
    width: 100%;
  }
  
  .webhook-table-header {
    font-weight: bold;
    background-color: #f1f1f1;
    padding: 8px;
  }
  
  .webhook-row {
    border-bottom: 1px solid #ccc;
    cursor: pointer;
  }
  
  .webhook-row-main {
    display: flex;
    padding: 10px;
    justify-content: space-between;
  }
  
  .accordion-details {
    background-color: #f1f1f1;
    padding: 10px;
    margin-top: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  